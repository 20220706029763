import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { BlockQueryKeys } from '../BlockQueryKeys';
import { useBlockData } from '../BlockData';
export function useBlockDataLocationEvents(locationId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getLocationEventsById } = useBlockData();
    const _b = useQuery(BlockQueryKeys.locationEvents(locationId), ({ signal }) => getLocationEventsById(locationId, { signal }), Object.assign({ enabled: enabled && !!locationId, staleTime: 60 * 1000 }, options)), { data: events, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: events, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}

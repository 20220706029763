import { __rest } from "tslib";
import { defineTranslations, i18n, TimeToDestination, } from '@introcloud/blocks';
import { useBlockDataEvent, useBlockDataFirstVisibleEvent, useForceUpdate, } from '@introcloud/blocks-interface';
import { FetchMediaError } from 'fetch-media';
import { createElement, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Card, Paragraph, Surface, useTheme } from 'react-native-paper';
import { getCurrentTime } from 'react-native-use-timestamp';
import { useIsMobileView } from '../../utils/useIsMobileView';
import { useRenderFallback } from '../../utils/useRenderFallback';
defineTranslations({
    en: {
        blocks: {
            countdown: {
                unavailable: 'At this moment the content is not yet accessible to you. Check back later!',
            },
        },
    },
    nl: {
        blocks: {
            countdown: {
                unavailable: 'Op dit moment is de content nog niet beschikbaar voor jou. Probeer het later nog eens!',
            },
        },
    },
});
export function CountDownBlock(block) {
    const { value: { eventId, destination }, } = block;
    if (eventId) {
        return createElement(CountDownToEvent, Object.assign(Object.assign({}, block), { eventId }));
    }
    if (destination) {
        return createElement(CountDownToDestination, Object.assign(Object.assign({}, block), { destination }));
    }
    return createElement(CountDownToFirstEvent, block);
}
function CountDownToFirstEvent(block) {
    const { data: event, error } = useBlockDataFirstVisibleEvent();
    const renderFallback = useRenderFallback(block);
    if (error instanceof FetchMediaError && error.response.status === 403) {
        return createElement(ApplicationNotReady, {});
    }
    if (error || !event) {
        return renderFallback();
    }
    const destination = event.duration.start.unix;
    return createElement(CountDownToDestination, Object.assign(Object.assign({}, block), { destination }));
}
function CountDownToEvent(_a) {
    var { eventId } = _a, block = __rest(_a, ["eventId"]);
    const { data, error } = useBlockDataEvent(eventId);
    const renderFallback = useRenderFallback(block);
    if (error || !data) {
        return renderFallback();
    }
    const destination = data.duration.start.unix;
    return createElement(CountDownToDestination, Object.assign(Object.assign({}, block), { destination }));
}
function CountDownToDestination(_a) {
    var { destination } = _a, block = __rest(_a, ["destination"]);
    const renderFallback = useRenderFallback(block);
    const forceUpdate = useForceUpdate();
    const isMobileView = useIsMobileView();
    // Update this block if it's focussed, not in the past and some time has
    // has passed since the last render
    useEffect(() => {
        if (!block.focussed) {
            return;
        }
        // Already done
        let now = getCurrentTime();
        if (now >= destination) {
            return;
        }
        let requestFrameId;
        const refresh = () => {
            requestFrameId = requestAnimationFrame(() => {
                const realNow = getCurrentTime();
                if (realNow - now > 250) {
                    now = realNow;
                    forceUpdate();
                }
                refresh();
            });
        };
        refresh();
        return () => {
            requestFrameId && cancelAnimationFrame(requestFrameId);
        };
    }, [block.focussed]);
    if (!destination || destination < 0) {
        return renderFallback();
    }
    return createElement(TimeToDestination, { date: destination, isMobileView });
}
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 100,
        paddingHorizontal: 16,
        elevation: 1,
    },
});
function ApplicationNotReady() {
    const { roundness } = useTheme();
    const isMobileView = useIsMobileView();
    return createElement(Surface, {
        style: [
            styles.container,
            {
                borderRadius: isMobileView ? 0 : roundness,
                marginTop: isMobileView ? 0 : 24,
            },
        ],
        children: createElement(Card.Content, {
            children: createElement(Paragraph, {
                children: i18n.t('blocks.countdown.unavailable'),
            }),
        }),
    });
}

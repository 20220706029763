import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Surface, useTheme } from 'react-native-paper';
import { LocationCard } from './LocationCard';
import { LocationListItem } from './LocationListItem';
import { Spacer } from './Spacer';
import { useLocalization } from './useLocale';
function renderLocationSmall(location, index, self, renderFallback, renderError) {
    return (React.createElement(LocationListItem, { key: typeof location === 'string' ? location : location.id, source: location, isLast: index === self.length - 1, renderFallback: renderFallback, renderError: renderError }));
}
function renderLocationBig(location, index, self, renderFallback, renderError) {
    const key = typeof location === 'string' ? location : location.id;
    return (React.createElement(Fragment, { key: key },
        React.createElement(LocationCard, { key: key, source: location, renderError: renderError, renderFallback: renderFallback }),
        index !== self.length - 1 && React.createElement(Spacer, { key: `${key}-spacer`, space: 1 })));
}
export function LocationList({ title, titleLocalized, locations, round, big, renderFallback, renderError, }) {
    const finalTitle = useLocalization(titleLocalized, title || '');
    const { roundness } = useTheme();
    if (locations.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    // TODO suspense instead
    const items = locations
        .map((location, index, self) => big
        ? renderLocationBig(location, index, self, renderFallback, renderError)
        : renderLocationSmall(location, index, self, renderFallback, renderError))
        .filter(Boolean);
    if (items.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Fragment, null,
        finalTitle ? (React.createElement(View, { style: styles.title },
            React.createElement(List.Subheader, null, finalTitle))) : null,
        big ? (items) : (React.createElement(Surface, { style: [styles.surface, borderStyle] },
            React.createElement(View, { style: [{ width: '100%', overflow: 'hidden' }, borderStyle] }, items)))));
}
const styles = StyleSheet.create({
    title: { width: '100%' },
    surface: { elevation: 1 },
});

import { __rest } from "tslib";
import React from 'react';
import { ActionBlock } from './blocks/ActionBlock';
import { ActionsBlock } from './blocks/ActionsBlock';
import { AutoBlock } from './blocks/AutoBlock';
import { ChatRoomBlock } from './blocks/ChatRoomBlock';
import { CheckInBlock } from './blocks/CheckInBlock';
import { ContactBlock } from './blocks/ContactBlock';
import { CustomBlock } from './blocks/CustomBlock';
import { DirectionsBlock } from './blocks/DirectionsBlock';
import { Divider } from './blocks/Divider';
import { EventBlock } from './blocks/EventBlock';
import { EventsBlock } from './blocks/EventsBlock';
import { GameMapBlock } from './blocks/GameMapBlock';
import { CountDownBlock } from './blocks/home/CountDownBlock';
import { EventsRelevantBlock } from './blocks/home/EventsRelevantBlock';
import { NewsBlock } from './blocks/home/NewsBlock';
import { PushNotificationsBlock } from './blocks/home/PushNotificationsBlock';
import { QuickChatBlock } from './blocks/home/QuickChatBlock';
import { QuickContactsBlock } from './blocks/home/QuickContactsBlock';
import { StoreLinksBlock } from './blocks/home/StoreLinksBlock';
import { ImageBlock } from './blocks/ImageBlock';
import { InterestedBlock } from './blocks/InterestedBlock';
import { LabelsBlock } from './blocks/LabelsBlock';
import { LocationBlock } from './blocks/LocationBlock';
import { LocationsBlock } from './blocks/LocationsBlock';
import { MapBlock } from './blocks/MapBlock';
import { Spacer } from './blocks/Spacer';
import { SponsorBlock } from './blocks/SponsorBlock';
import { TableBlock } from './blocks/TableBlock';
import { TextBlock } from './blocks/TextBlock';
import { TimeSlotsBlock } from './blocks/TimeSlotsBlock';
import { TimeSpanBlock } from './blocks/TimeSpanBlock';
import { VideoEmbedBlock } from './blocks/VideoEmbedBlock';
import { VideoStreamBlock } from './blocks/VideoStreamBlock';
import { ErrorHandlerContext } from './ErrorHandler';
import { ImageCarouselBlock } from './blocks/ImageCarouselBlock';
const TypedErrorHandlerContext = ErrorHandlerContext;
export class PureBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            hasReportedError: false,
        };
        this.retry = this.retry.bind(this);
    }
    static getDerivedStateFromError(error) {
        return { error };
    }
    componentDidCatch(error, errorInfo) {
        if (this.state.hasReportedError) {
            return;
        }
        this.setState((prev) => (Object.assign(Object.assign({}, prev), { hasReportedError: true })));
        this.context.reportError(this.props, error, errorInfo);
    }
    retry() {
        this.setState({ error: undefined, hasReportedError: false });
    }
    render() {
        const _a = this.props, { children } = _a, block = __rest(_a, ["children"]);
        const hasError = !!this.state.error;
        // Handle errors or rethrow
        if (hasError) {
            if (this.context.handleError) {
                return this.context.renderError(block, this.state.error, this.retry);
            }
            throw this.state.error;
        }
        if (children) {
            return children(block);
        }
        return React.createElement(Block, Object.assign({}, block));
    }
}
PureBlock.contextType = TypedErrorHandlerContext;
export function Block(block) {
    switch (block.kind) {
        case 'action': {
            return React.createElement(ActionBlock, Object.assign({}, block));
        }
        case 'actions': {
            return React.createElement(ActionsBlock, Object.assign({}, block));
        }
        case 'auto': {
            return React.createElement(AutoBlock, Object.assign({}, block));
        }
        case 'chat': {
            return React.createElement(ChatRoomBlock, Object.assign({}, block));
        }
        case 'check-in': {
            return React.createElement(CheckInBlock, Object.assign({}, block));
        }
        case 'contact': {
            return React.createElement(ContactBlock, Object.assign({}, block));
        }
        case 'count-down': {
            return React.createElement(CountDownBlock, Object.assign({}, block));
        }
        case 'custom': {
            return React.createElement(CustomBlock, Object.assign({}, block));
        }
        case 'directions': {
            return React.createElement(DirectionsBlock, Object.assign({}, block));
        }
        case 'divider': {
            return React.createElement(Divider, Object.assign({}, block));
        }
        case 'event': {
            return React.createElement(EventBlock, Object.assign({}, block));
        }
        case 'events': {
            return React.createElement(EventsBlock, Object.assign({}, block));
        }
        case 'events-relevant': {
            return React.createElement(EventsRelevantBlock, Object.assign({}, block));
        }
        case 'labels': {
            return React.createElement(LabelsBlock, Object.assign({}, block));
        }
        case 'game-map': {
            return React.createElement(GameMapBlock, Object.assign({}, block));
        }
        case 'image': {
            return React.createElement(ImageBlock, Object.assign({}, block));
        }
        case 'image-carousel': {
            return React.createElement(ImageCarouselBlock, Object.assign({}, block));
        }
        case 'interested': {
            return React.createElement(InterestedBlock, Object.assign({}, block));
        }
        case 'live': {
            return React.createElement(VideoStreamBlock, Object.assign({}, block));
        }
        case 'location': {
            return React.createElement(LocationBlock, Object.assign({}, block));
        }
        case 'locations': {
            return React.createElement(LocationsBlock, Object.assign({}, block));
        }
        case 'map': {
            return React.createElement(MapBlock, Object.assign({}, block));
        }
        case 'news': {
            return React.createElement(NewsBlock, Object.assign({}, block));
        }
        case 'push-notifications': {
            return React.createElement(PushNotificationsBlock, Object.assign({}, block));
        }
        case 'quick-chat': {
            return React.createElement(QuickChatBlock, Object.assign({}, block));
        }
        case 'quick-contacts': {
            return React.createElement(QuickContactsBlock, Object.assign({}, block));
        }
        case 'spacer': {
            return React.createElement(Spacer, Object.assign({}, block));
        }
        case 'sponsor': {
            return React.createElement(SponsorBlock, Object.assign({}, block));
        }
        case 'store-links': {
            return React.createElement(StoreLinksBlock, Object.assign({}, block));
        }
        case 'table': {
            return React.createElement(TableBlock, Object.assign({}, block));
        }
        case 'timeslots': {
            return React.createElement(TimeSlotsBlock, Object.assign({}, block));
        }
        case 'timespan': {
            return React.createElement(TimeSpanBlock, Object.assign({}, block));
        }
        case 'text': {
            return React.createElement(TextBlock, Object.assign({}, block));
        }
        case 'video': {
            return React.createElement(VideoEmbedBlock, Object.assign({}, block));
        }
        default: {
            throw new Error(`Block ${block.kind} is not recognized.`);
        }
    }
}

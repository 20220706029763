import React, { Fragment } from 'react';
import { View } from 'react-native';
import { List } from 'react-native-paper';
import { IconGrid } from './IconGrid';
import { ImageGrid } from './ImageGrid';
import { useLocalization } from './useLocale';
export function ActionGrid({ title, titleLocalized, actions, mode, size, resizeMode, ratio, renderFallback, }) {
    const finalTitle = useLocalization(titleLocalized, title || '');
    if (actions.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    return (React.createElement(Fragment, null,
        finalTitle ? (React.createElement(View, { style: { width: '100%' } },
            React.createElement(List.Subheader, null, finalTitle))) : null,
        mode === 'images' ? (React.createElement(ImageGrid, { ratio: ratio, resizeMode: resizeMode, size: size, actions: actions })) : null,
        mode === 'icons' ? (React.createElement(IconGrid, { size: size, actions: actions })) : null));
}

import { useWindowWidth } from '@introcloud/blocks-interface';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { PanGestureHandler, } from 'react-native-gesture-handler';
import { useTheme } from 'react-native-paper';
import Animated, { useAnimatedGestureHandler, useAnimatedReaction, useAnimatedStyle, useSharedValue, withSpring, withTiming, } from 'react-native-reanimated';
import { snapPoint } from 'react-native-redash';
import { PureImageGridItem } from './ImageGrid';
export function ImageCarousel({ actions, resizeMode, controls = true, autoDelay = -1, ratio = [1, 1], round = { top: true, bottom: true }, }) {
    const { colors: { placeholder, surface }, roundness, } = useTheme();
    const windowWidth = useWindowWidth();
    const width = Math.floor(Math.min(720, windowWidth));
    const height = Math.floor((width / ratio[0]) * ratio[1]);
    const [snapPoints, setSnapPoints] = useState([0]);
    const translateX = useSharedValue(0);
    const timer = useSharedValue(1);
    // React on the timer getting to 0
    useAnimatedReaction(() => timer.value === 0, (ready, wasReady) => {
        if (!ready || wasReady) {
            return;
        }
        const nextIndex = (Math.abs(Math.round(translateX.value / width)) + 1) % actions.length;
        const nextX = -nextIndex * width;
        translateX.value = withSpring(nextX, {
            damping: 50,
            mass: 1,
            stiffness: 100,
        });
        if (autoDelay > 0) {
            timer.value = 1;
            timer.value = withTiming(0, { duration: autoDelay });
        }
    }, [timer, translateX, autoDelay, width, actions.length]);
    // Kick start timer
    useEffect(() => {
        if (autoDelay > 0) {
            timer.value = withTiming(0, { duration: autoDelay });
        }
    }, [autoDelay]);
    useLayoutEffect(() => {
        const next = [];
        actions.forEach((_, i) => {
            next.push(i * -width);
        });
        if (next.length === 0) {
            next.push(0);
        }
        console.log(next);
        setSnapPoints(next);
    }, [actions, width]);
    // Animate styling
    const style = useAnimatedStyle(() => ({
        transform: [{ translateX: translateX.value }],
    }), [translateX]);
    // Gesture tracking
    const onGestureEvent = useAnimatedGestureHandler({
        onStart: (_, ctx) => {
            ctx.x = translateX.value;
            // Stop timer
            timer.value = -1;
        },
        onActive: ({ translationX }, ctx) => {
            translateX.value = ctx.x + translationX;
        },
        onEnd: ({ velocityX }) => {
            const dest = snapPoint(translateX.value, velocityX, snapPoints);
            translateX.value = withSpring(dest, {
                damping: 50,
                mass: 1,
                stiffness: 100,
            });
            // index.value = Math.abs(dest / width);
            if (autoDelay >= 0) {
                timer.value = 1;
                timer.value = withTiming(0, { duration: autoDelay });
            }
        },
    }, [translateX, snapPoints, width, timer, autoDelay]);
    return (React.createElement(PanGestureHandler, { onGestureEvent: onGestureEvent },
        React.createElement(Animated.View, { style: {
                width,
                height,
                maxWidth: '100%',
                overflow: 'hidden',
                backgroundColor: '#111',
                borderTopLeftRadius: round.top ? roundness : 0,
                borderTopRightRadius: round.top ? roundness : 0,
                borderBottomLeftRadius: round.bottom ? roundness : 0,
                borderBottomRightRadius: round.bottom ? roundness : 0,
            } },
            React.createElement(Animated.View, { style: [style, styles.carouselItems] }, actions.map((action, index) => {
                return (React.createElement(PureImageGridItem, { action: action, key: index, placeholder: placeholder, width: width, height: height, roundness: 0, resizeMode: resizeMode, surface: surface, resize: false }));
            })))));
}
const styles = StyleSheet.create({
    carouselItems: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
});

import { LabelList, useLocalization } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { Surface } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
export function LabelsBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { labels, labelsLocalized }, } = block;
    const tags = useLocalization(labelsLocalized, labels);
    if (!tags || tags.length === 0) {
        return renderFallback();
    }
    return createElement(Surface, {
        key: 'surface',
        style: {
            elevation: 1,
            paddingHorizontal: 16,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingBottom: 6,
        },
        children: createElement(LabelList, {
            tags,
        }),
    });
}

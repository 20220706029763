import { useDestination, useWindowWidth } from '@introcloud/blocks-interface';
import { LinearGradient } from 'expo-linear-gradient';
import React, { Fragment, useMemo } from 'react';
import { Image, Platform, StyleSheet, View, } from 'react-native';
import { Card, Title, useTheme } from 'react-native-paper';
import { useLocalization } from './useLocale';
function resizeImageUrl(url) {
    if (!url) {
        return url;
    }
    if (!url.startsWith('https://api.tactile.events/api/image/')) {
        return url;
    }
    return url.replace('/icon_1440', '/icon_512');
}
function sizeToColumns(size) {
    switch (size) {
        case 'tiny': {
            return 4;
        }
        case 'small': {
            return 3;
        }
        case 'large': {
            return 2;
        }
        default: {
            return 3;
        }
    }
}
export function ImageGrid({ actions, resizeMode, size, ratio = [1, 1], }) {
    const { colors: { placeholder, surface }, roundness, } = useTheme();
    const windowWidth = useWindowWidth();
    const columns = sizeToColumns(size);
    const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);
    const height = Math.floor((width / ratio[0]) * ratio[1]);
    const empty = useMemo(() => {
        const result = [];
        const remainder = columns - ((actions.length % columns) % columns);
        if (remainder === columns) {
            return result;
        }
        for (let i = 0; i < remainder; i++) {
            result.push(Math.random().toString(36));
        }
        return result;
    }, [actions.length, columns]);
    return (React.createElement(View, { style: styles.gridItemWrapper },
        actions.map((action, index) => {
            return (React.createElement(PureImageGridItem, { action: action, key: index, placeholder: placeholder, width: width, height: height, roundness: roundness, resizeMode: resizeMode, surface: surface, resize: true }));
        }),
        empty.map((none) => (React.createElement(View, { style: { width, height }, key: none })))));
}
const SCRIM_GRADIENT = [
    'transparent',
    'transparent',
    'rgba(0, 0, 0, .3)',
    'rgba(0, 0, 0, .6)',
];
const SCRIM_INVERSE_GRADIENT = [
    'transparent',
    'transparent',
    'rgba(255, 255, 255, .3)',
    'rgba(255, 255, 255, .6)',
];
export function ImageCard({ action, surface, placeholder, size, width = size, height = size, roundness, resizeMode, }) {
    var _a, _b;
    const finalAccessibilityLabel = useLocalization(action.accessibilityLabelLocalized, action.accessibilityLabel || '');
    const finalLabel = useLocalization(action.labelLocalized, action.label);
    const onPress = useDestination(action.destination);
    const sizeStyle = {
        width,
        height,
    };
    const backgroundStyle = {};
    if (action.backgroundColor) {
        backgroundStyle['backgroundColor'] = action.backgroundColor;
    }
    if (action.borderColor) {
        backgroundStyle['borderColor'] = action.borderColor;
        backgroundStyle['borderWidth'] = 2;
        backgroundStyle['borderRadius'] = roundness + 2;
    }
    const hasAction = Boolean(action.destination.value);
    return (React.createElement(Card, { contentStyle: { flex: 1 }, style: [
            styles.imageCard,
            sizeStyle,
            backgroundStyle,
            hasAction && Platform.OS === 'web'
                ? { cursor: 'pointer' }
                : {},
        ], onPress: hasAction ? onPress : undefined, accessible: true, accessibilityRole: hasAction ? 'imagebutton' : 'image', accessibilityLabel: finalAccessibilityLabel || finalLabel, elevation: hasAction ? undefined : 0 }, resizeMode === 'contain' ? (React.createElement(ContainedImage, { action: action, width: width - (action.borderColor ? 4 : 0), height: height - (action.borderColor ? 4 : 0), surface: (_a = action.backgroundColor) !== null && _a !== void 0 ? _a : surface, placeholder: placeholder, roundness: roundness })) : (React.createElement(CoveredImage, { action: action, width: width - (action.borderColor ? 4 : 0), height: height - (action.borderColor ? 4 : 0), surface: (_b = action.backgroundColor) !== null && _b !== void 0 ? _b : surface, roundness: roundness, placeholder: placeholder }))));
}
export const PureImageGridItem = React.memo(ImageCard);
function ContainedImage({ action, size, width = size, height = size, roundness, surface, placeholder, resize, }) {
    const finalLabel = (useLocalization(action.labelLocalized, action.label) || '').trim();
    const finalImage = useLocalization(action.imageLocalized, action.image);
    const source = typeof finalImage === 'number'
        ? finalImage
        : {
            uri: resize ? resizeImageUrl(finalImage) : finalImage,
            width: width - 32,
            height: height - 32,
        };
    return (React.createElement(Card.Content, { style: { marginVertical: 16 } },
        React.createElement(Image, { resizeMode: "contain", source: source, style: {
                backgroundColor: finalImage ? surface : placeholder,
                borderRadius: roundness,
            } }),
        finalLabel ? (React.createElement(Fragment, null,
            React.createElement(LinearGradient, { colors: SCRIM_INVERSE_GRADIENT, style: [
                    {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: -16,
                        bottom: -16,
                        borderRadius: roundness,
                    },
                ] }),
            React.createElement(Title, { style: styles.imageContainLabel2 }, finalLabel))) : null));
}
function CoveredImage({ action, size, width = size, height = size, surface, placeholder, roundness, resize, }) {
    const finalLabel = (useLocalization(action.labelLocalized, action.label) || '').trim();
    const finalImage = useLocalization(action.imageLocalized, action.image);
    const source = typeof finalImage === 'number'
        ? finalImage
        : {
            uri: resize ? resizeImageUrl(finalImage) : finalImage,
            width,
            height,
        };
    return (React.createElement(Fragment, null,
        React.createElement(Image, { resizeMode: "cover", source: source, style: {
                backgroundColor: finalImage ? surface : placeholder,
                borderRadius: roundness,
            } }),
        finalLabel ? (React.createElement(Fragment, null,
            React.createElement(LinearGradient, { colors: SCRIM_GRADIENT, style: [StyleSheet.absoluteFillObject, { borderRadius: roundness }] }),
            React.createElement(Title, { style: styles.imageCoverLabel }, finalLabel))) : null));
}
const styles = StyleSheet.create({
    gridItemWrapper: {
        flexWrap: 'wrap',
        paddingHorizontal: 8,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
    },
    imageCard: {
        margin: 0,
        marginBottom: 16,
    },
    imageContainLabel2: {
        color: 'black',
        bottom: 0,
        top: undefined,
        left: 0,
        right: 0,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        paddingBottom: 6,
        paddingLeft: 24,
    },
    imageCoverLabel: {
        color: 'white',
        padding: 8,
        bottom: 0,
        top: undefined,
        left: 0,
        right: 0,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        paddingLeft: 12,
    },
    imageContainLabel: {
        color: 'black',
        textShadowColor: 'rgba(255, 255, 255, .56)',
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        bottom: 0,
        left: 0,
        right: 0,
        top: undefined,
        paddingBottom: 18,
        paddingLeft: 24,
    },
});

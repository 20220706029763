import { __rest } from "tslib";
import { fetchRegisteredTimeslot, } from '@introcloud/api-client';
import { AccentButton, defineTranslations, i18n, PrimaryButton, TextButton, useLocalization, } from '@introcloud/blocks';
import { useBlockDataCheckinStatus, useBlockDataEvent, useBlockDataTimeslots, useBlockImageUrl, } from '@introcloud/blocks-interface';
import { createElement, Fragment, memo, useCallback, useMemo, useState, } from 'react';
import { Platform, View } from 'react-native';
import { ActivityIndicator, Avatar, Caption, Dialog, Paragraph, Portal, ProgressBar, Surface, Title, useTheme, } from 'react-native-paper';
import { localeDateString, localeTimeString } from 'react-native-time-helpers';
import { useInaccurateTimestamp, useTimestamp, } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
defineTranslations({
    en: {
        blocks: {
            timeslot: {
                name: 'Timeslot',
                description: 'Slot: {{name}} ({{time}})',
            },
            check_in: {
                waitlisted: 'Waitlisted',
                future: 'Please wait...',
                past: 'Expired',
                status: {
                    description: 'The animated bar below indicates an active, valid, check-in.',
                    indicator: 'Checked in at {{date}} on {{time}}',
                    seconds: "That's {{seconds}} seconds ago.",
                    minutes: "That's about {{minutes}} minutes ago.",
                    hours: "That's more than an hour ago",
                },
            },
            actions: {
                check_in: {
                    self: 'Self check-in',
                    show: 'Show check-in',
                },
            },
        },
    },
    nl: {
        blocks: {
            timeslot: {
                name: 'Tijdslot',
                description: 'Slot: {{name}} ({{time}})',
            },
            check_in: {
                waitlisted: 'Wachtrij',
                future: 'Even geduld...',
                past: 'Verlopen',
                status: {
                    description: 'De animatie hieronder geeft een actieve, geldige check-in aan.',
                    indicator: 'Ingecheckt op {{date}} om {{time}}',
                    seconds: 'Dat is {{seconds}} seconden geleden.',
                    minutes: 'Dat is ongeveer {{minutes}} minuten geleden.',
                    hours: 'Dat is meer dan een uur geleden',
                },
            },
            actions: {
                check_in: {
                    self: 'Inchecken',
                    show: 'Toon bewijs',
                },
            },
        },
    },
});
export function CheckInBlock(block) {
    const { pageEvent } = usePageData();
    const eventForCheckinId = (block.value.event === 'auto' ? pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent._id : block.value.event) || '';
    const { data, change, error, refreshing, loading } = useBlockDataCheckinStatus(eventForCheckinId);
    const { roundness } = useTheme();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const target = block.value.target || 'arrive';
    const active = !!(data && data.id === target);
    const action = useLocalization(block.value.labelLocalized, block.value.label || i18n.t('blocks.actions.check_in.self'));
    const actionLabel = active ? i18n.t('blocks.actions.check_in.show') : action;
    if (!eventForCheckinId) {
        return renderFallback();
    }
    if (error && !data) {
        return renderFallback();
    }
    const { options: { spacing, round, mode, gate, block_waitlisted: blockWaitlisted }, next, previous, dragging, } = block;
    const roundTop = round && !extendsFullyToBottom(previous);
    const roundBottom = round && !extendsFullyToTop(next);
    const topRadius = roundTop ? roundness : 0;
    const bottomRadius = roundBottom ? roundness : 0;
    const shouldCollapseMarginTop = !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const fixedSpace = spacing === undefined ? 2 : spacing;
    const style = fixedSpace
        ? {
            paddingTop: shouldCollapseMarginTop ? 0 : fixedSpace * 8,
            paddingBottom: fixedSpace * 8,
        }
        : undefined;
    return createElement(Surface, {
        style: [
            style,
            {
                elevation: 1,
                borderTopLeftRadius: topRadius,
                borderTopRightRadius: topRadius,
                borderBottomLeftRadius: bottomRadius,
                borderBottomRightRadius: bottomRadius,
            },
        ],
        children: [
            createElement(View, {
                key: 'button',
                style: {
                    marginLeft: 16,
                    marginRight: 'auto',
                },
            }, createElement(gate === 'timeslot' ? TimeslotCheckinButton : EventCheckinButton, {
                disabled: loading || refreshing,
                active,
                change,
                target,
                data,
                label: actionLabel,
                icon: active ? 'check-circle' : 'check-circle-outline',
                loading: loading || refreshing,
                mode,
                surface: false,
                inset: true,
                eventId: eventForCheckinId,
                blockWaitlisted,
            })),
        ],
    });
}
function TimeslotCheckinButton(_a) {
    var { eventId, disabled, label, active, target, change, data, blockWaitlisted } = _a, props = __rest(_a, ["eventId", "disabled", "label", "active", "target", "change", "data", "blockWaitlisted"]);
    const [showDialog, setShowDialog] = useState(false);
    const hideDialog = useCallback(() => setShowDialog(false), []);
    const onPress = useCallback(() => {
        if (active) {
            setShowDialog(true);
            return;
        }
        change(target).then(() => {
            setShowDialog(true);
        });
    }, [change, active, target]);
    const { data: event, loading } = useBlockDataEvent(eventId);
    const { data: timeslots, loading: loadingTimeslots } = useBlockDataTimeslots(eventId);
    const timeslot = timeslots && fetchRegisteredTimeslot(timeslots.timeslots);
    // Disable check because the module says it's disabled
    const disableCheckIn = useMemo(() => {
        var _a;
        if (!event || !((_a = event.module) === null || _a === void 0 ? void 0 : _a.application)) {
            return false;
        }
        if (active) {
            return false;
        }
        return !event.module.application.canCheckIn;
    }, [event, active]);
    const startBoundary = (timeslot === null || timeslot === void 0 ? void 0 : timeslot.start.unix)
        ? (timeslot === null || timeslot === void 0 ? void 0 : timeslot.start.unix) - 60 * 15 * 1000
        : 0;
    const endBoundary = (timeslot === null || timeslot === void 0 ? void 0 : timeslot.end.unix)
        ? (timeslot === null || timeslot === void 0 ? void 0 : timeslot.end.unix) + 60 * 15 * 1000
        : 0;
    const timeslotName = timeslot
        ? (timeslot.name.full || '').toLocaleUpperCase()
        : null;
    const isWaitlisted = Boolean(blockWaitlisted && timeslotName && timeslotName.includes('WACHTRIJ'));
    return createElement(Fragment, undefined, [
        createElement(TimedCheckInButton, Object.assign(Object.assign({}, props), { onPress, key: 'button', disabled: disabled ||
                loading ||
                loadingTimeslots ||
                !timeslot ||
                disableCheckIn ||
                isWaitlisted, label,
            startBoundary,
            endBoundary,
            isWaitlisted })),
        createElement(CheckInDialog, {
            key: 'dialog',
            visible: showDialog,
            onDismiss: hideDialog,
            data,
            event,
        }),
    ]);
}
function EventCheckinButton(_a) {
    var { eventId, disabled, label, active, target, change, data } = _a, props = __rest(_a, ["eventId", "disabled", "label", "active", "target", "change", "data"]);
    const [showDialog, setShowDialog] = useState(false);
    const hideDialog = useCallback(() => setShowDialog(false), []);
    const onPress = useCallback(() => {
        if (active) {
            setShowDialog(true);
            return;
        }
        change(target).then(() => {
            setShowDialog(true);
        });
    }, [change, active, target]);
    const { data: event, loading } = useBlockDataEvent(eventId);
    // Disable check because the module says it's disabled
    const disableCheckIn = useMemo(() => {
        var _a;
        if (!event || !((_a = event.module) === null || _a === void 0 ? void 0 : _a.application)) {
            return false;
        }
        if (active) {
            return false;
        }
        return !event.module.application.canCheckIn;
    }, [event, active]);
    const startBoundary = (event === null || event === void 0 ? void 0 : event.duration.start.unix) || 0;
    const endBoundary = (event === null || event === void 0 ? void 0 : event.duration.end.unix) || 0;
    return createElement(Fragment, undefined, [
        createElement(TimedCheckInButton, Object.assign(Object.assign({}, props), { onPress, key: 'button', disabled: disabled || loading || disableCheckIn, label,
            startBoundary,
            endBoundary })),
        createElement(CheckInDialog, {
            key: 'dialog',
            visible: showDialog,
            onDismiss: hideDialog,
            data,
            event,
        }),
    ]);
}
function TimedCheckInButton(_a) {
    var _b;
    var { disabled, label, startBoundary, endBoundary, isWaitlisted } = _a, props = __rest(_a, ["disabled", "label", "startBoundary", "endBoundary", "isWaitlisted"]);
    const now = useInaccurateTimestamp({
        every: Platform.select({ web: 10 * 1000, default: 1500 }),
    });
    const timeDisabled = now < startBoundary || now > endBoundary;
    const timeLabel = timeDisabled
        ? now < startBoundary
            ? i18n.t('blocks.check_in.future')
            : i18n.t('blocks.check_in.past')
        : undefined;
    const waitlistLabel = isWaitlisted
        ? i18n.t('blocks.check_in.waitlisted')
        : undefined;
    return createElement(CheckInButton, Object.assign(Object.assign({}, props), { disabled: disabled || timeDisabled, label: (_b = timeLabel !== null && timeLabel !== void 0 ? timeLabel : waitlistLabel) !== null && _b !== void 0 ? _b : label }));
}
function CheckInDialog({ visible, onDismiss, data, event, }) {
    var _a, _b;
    const { data: timeslotData } = useBlockDataTimeslots((event === null || event === void 0 ? void 0 : event._id) || '');
    const currentTimeslot = timeslotData === null || timeslotData === void 0 ? void 0 : timeslotData.timeslots.find((timeslot) => timeslot.registeredEventGuest);
    const { roundness } = useTheme();
    const { currentUser } = usePageData();
    const contextName = event === null || event === void 0 ? void 0 : event.name.full;
    const { data: imageUrl } = useBlockImageUrl((currentUser === null || currentUser === void 0 ? void 0 : currentUser.image.profile) || null, 'icon_256');
    const initials = [(_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.first) === null || _a === void 0 ? void 0 : _a[0], (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.last) === null || _b === void 0 ? void 0 : _b[1]]
        .filter(Boolean)
        .join('');
    return createElement(Portal, undefined, createElement(Dialog, {
        style: {
            alignSelf: 'center',
            maxWidth: 320,
            width: '100%',
        },
        visible: visible && data !== null,
        onDismiss,
        children: [
            createElement(Dialog.Content, {
                key: 'content',
                children: data
                    ? createElement(View, { style: { alignItems: 'center' } }, [
                        imageUrl
                            ? createElement(Avatar.Image, {
                                key: 'image',
                                size: 96,
                                source: { uri: imageUrl },
                            })
                            : createElement(Avatar.Text, {
                                key: 'image',
                                size: 96,
                                label: initials,
                            }),
                        contextName &&
                            createElement(Title, {
                                key: 'title',
                                style: { marginTop: 12, textAlign: 'center' },
                                children: contextName,
                            }),
                        visible &&
                            createElement(CheckInTimeIndicator, {
                                key: 'indicator',
                                time: data.put.at.unix,
                            }),
                        currentTimeslot
                            ? createElement(CheckInTimeslot, {
                                key: 'timeslot',
                                timeslot: currentTimeslot,
                            })
                            : null,
                    ])
                    : createElement(ActivityIndicator, {
                        key: 'animation',
                        style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 32,
                        },
                    }),
            }),
            createElement(Caption, {
                key: 'relative',
                style: {
                    marginTop: 16,
                    marginLeft: 16,
                    marginRight: 16,
                    textAlign: 'center',
                },
                children: i18n.t('blocks.check_in.status.description'),
            }),
            createElement(ProgressBar, {
                key: 'progress',
                indeterminate: true,
                style: {
                    borderBottomRightRadius: roundness,
                    borderBottomLeftRadius: roundness,
                },
            }),
        ],
    }));
}
function CheckInTimeslot({ timeslot }) {
    const name = timeslot.name.full || i18n.t('blocks.timeslot.name');
    const time = localeTimeString(new Date(timeslot.start.unix));
    return createElement(View, { style: { marginTop: 8, marginBottom: 8 } }, [
        createElement(Paragraph, {
            key: 'timeslot',
            children: i18n.t('blocks.timeslot.description', { name, time }),
            style: {
                textAlign: 'center',
                fontWeight: 'bold',
            },
        }),
    ]);
}
function CheckInTimeIndicator({ time }) {
    const timestamp = useTimestamp({ every: 1000 });
    const date = new Date(time);
    const seconds = Math.round((timestamp - time) / 1000);
    return createElement(View, { style: { marginTop: 32 } }, [
        createElement(Paragraph, {
            key: 'time',
            children: i18n.t('blocks.check_in.status.indicator', {
                date: localeTimeString(date),
                time: localeDateString(date, true),
            }),
            style: {
                textAlign: 'center',
            },
        }),
        createElement(Paragraph, {
            key: 'relative',
            children: seconds < 91
                ? i18n.t('blocks.check_in.status.seconds', { seconds })
                : seconds < 60 * 60
                    ? i18n.t('blocks.check_in.status.minutes', {
                        minutes: Math.round(seconds / 60),
                    })
                    : i18n.t('blocks.check_in.status.hours'),
            style: {
                textAlign: 'center',
            },
        }),
    ]);
}
function CheckInButton_(_a) {
    var { mode, label, onPress, loading } = _a, other = __rest(_a, ["mode", "label", "onPress", "loading"]);
    switch (mode) {
        case 'primary': {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        case 'accent': {
            return createElement(AccentButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        case 'text': {
            return createElement(TextButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        default: {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
    }
}
const CheckInButton = memo(CheckInButton_);

import React, { Fragment } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, MD2LightTheme as DefaultTheme } from 'react-native-paper';
import { normalizeTag } from './utils';
export function LabelList({ tags }) {
    if (!tags || tags.length === 0) {
        return null;
    }
    return (React.createElement(Fragment, null, tags.map((tag) => (React.createElement(Label, { key: tag, tag: tag })))));
}
function Label({ tag }) {
    return (React.createElement(Chip, { mode: "outlined", theme: DefaultTheme, style: [styles.tag, styles.designed], textStyle: styles.textDesigned, pointerEvents: "none" }, normalizeTag(tag)));
}
const styles = StyleSheet.create({
    textDesigned: {
        fontSize: 10,
        margin: 0,
        padding: 0,
        minHeight: 12,
        lineHeight: 12,
    },
    designed: {
        paddingVertical: 0,
        lineHeight: 12,
        minHeight: 12,
        backgroundColor: 'rgba(0, 0, 0, .05)',
        borderColor: 'rgba(0, 0, 0, .12)',
    },
    tag: {
        marginTop: 6,
        width: 'auto',
        marginRight: 6,
        justifyContent: 'center',
    },
});

export const KIND_ACTION = 'action';
export const KIND_ACTION_LIST = 'actions';
export const KIND_AUTO = 'auto';
export const KIND_BALANCE = 'balance';
export const KIND_CHAT = 'chat';
export const KIND_CHECK_IN = 'check-in';
export const KIND_CONTACT = 'contact';
export const KIND_CUSTOM = 'custom';
export const KIND_DIRECTIONS = 'directions';
export const KIND_DIVIDER = 'divider';
export const KIND_EVENT = 'event';
export const KIND_EVENTS = 'events';
export const KIND_GAME_MAP = 'game-map';
export const KIND_HOME_COUNTDOWN = 'count-down';
export const KIND_HOME_EVENTS_RELEVANT = 'events-relevant';
export const KIND_HOME_NEWS = 'news';
export const KIND_HOME_PUSH_NOTIFICATIONS = 'push-notifications';
export const KIND_HOME_QUICK_CHAT = 'quick-chat';
export const KIND_HOME_QUICK_CONTACTS = 'quick-contacts';
export const KIND_HOME_STORE_LINKS = 'store-links';
export const KIND_IMAGE = 'image';
export const KIND_IMAGE_CAROUSEL = 'image-carousel';
export const KIND_INTERESTED = 'interested';
export const KIND_LABELS = 'labels';
export const KIND_LOCATION = 'location';
export const KIND_LOCATIONS = 'locations';
export const KIND_MAP = 'map';
export const KIND_SPACER = 'spacer';
export const KIND_SPONSOR = 'sponsor';
export const KIND_SWITCHER = 'switcher';
export const KIND_TABLE = 'table';
export const KIND_TEXT = 'text';
export const KIND_TIME_SLOTS = 'timeslots';
export const KIND_TIME_SPAN = 'timespan';
export const KIND_VIDEO_EMBED = 'video';
export const KIND_VIDEO_STREAM = 'live';

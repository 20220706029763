import { fetchMedia, typeCheck } from './core';
import { RequiresDomain } from './errors';
import { TactileCompany } from './types';

const ACCEPT = 'application/json';

/**
 * Fetch the company
 *
 * @see {fetchApplication}
 * @see {fetchApplicationVisuals}
 *
 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
export async function fetchPublicCompany(
  endpoint: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileCompany> {
  if (!endpoint) {
    throw new RequiresDomain();
  }
  const result = await fetchMedia(`${endpoint}/public/company`, {
    headers: {
      accept: ACCEPT,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
  return typeCheck(result);
}

import { EventList } from '@introcloud/blocks';
import { useBlockDataLocationEvents, useBlockDataSubEvents, } from '@introcloud/blocks-interface';
import { createElement, useCallback, useMemo } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function EventsBlock(block) {
    var _a;
    const mode = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.mode) || 'manual';
    switch (mode) {
        case 'location': {
            return createElement(ContextUpcomingLocationEventsBlock, block);
        }
        case 'sub-events': {
            return createElement(ContextSubEventsBlock, block);
        }
        default: {
            return createElement(ManualEventsBlock, block);
        }
    }
}
export function ManualEventsBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { id, value: { ids, label, labelLocalized }, options, previous, next, } = block;
    // TODO suspend
    return createElement(EventList, {
        key: id,
        events: ids,
        title: label,
        titleLocalized: labelLocalized,
        round: (options === null || options === void 0 ? void 0 : options.round)
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined,
        renderFallback,
    });
}
function ContextSubEventsBlock(block) {
    const { pageEvent } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!pageEvent) {
        return renderFallback();
    }
    return createElement(SubEventsBlock, Object.assign({ event: pageEvent }, block));
}
function ContextUpcomingLocationEventsBlock(block) {
    const { pageLocation } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    if (!pageLocation) {
        return renderFallback();
    }
    return createElement(UpcomingEventsBlock, Object.assign({ location: pageLocation }, block));
}
const EMPTY = [];
export function SubEventsBlock(block) {
    const { data: events, loading, error, } = useBlockDataSubEvents(block.event._id);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const subEvents = useMemo(() => (events ? events.map((event) => event._id) : EMPTY), [events]);
    if (loading || !subEvents || subEvents.length === 0) {
        return renderFallback();
    }
    if (error) {
        return renderFallback();
    }
    return createElement(EventList, {
        key: block.id,
        events: subEvents,
        title: block.value.label,
        titleLocalized: block.value.labelLocalized,
        renderFallback,
    });
}
export function UpcomingEventsBlock(block) {
    const locationId = block.location._id;
    const { data: events, loading, error, } = useBlockDataLocationEvents(locationId);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const locationEvents = useMemo(() => (events ? events.map((event) => event._id) : EMPTY), [events]);
    if (loading || !locationEvents || locationEvents.length === 0) {
        return renderFallback();
    }
    if (error) {
        return renderFallback();
    }
    return createElement(EventList, {
        key: block.id,
        events: locationEvents,
        title: block.value.label,
        titleLocalized: block.value.labelLocalized,
        renderFallback,
    });
}

import { createElement } from 'react';
import { Surface, Text } from 'react-native-paper';
const DEFAULT_STYLE = {
    elevation: 1,
};
export function QuickChatBlock(block) {
    const style = DEFAULT_STYLE;
    // TODO suspend
    return createElement(Surface, {
        key: 'surface',
        style,
        children: createElement(Text, undefined, `[block placeholder] ${block.kind}`),
    });
}

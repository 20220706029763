import { __rest } from "tslib";
import { useBlockData, useBlockDataLocation, } from '@introcloud/blocks-interface';
import React from 'react';
import { LocalLocationCard } from './LocalLocationCard';
import { localize, useLocale } from './useLocale';
const EMPTY_LOCATION_DATA = {
    id: '',
    coordinates: undefined,
    title: '',
    description: '',
};
export function RemoteLocationCard(_a) {
    var _b, _c, _d;
    var { id, renderFallback, renderError } = _a, options = __rest(_a, ["id", "renderFallback", "renderError"]);
    const locale = useLocale();
    const { data: location, loading, error } = useBlockDataLocation(id);
    const { getImageUrl } = useBlockData();
    if (loading || !location) {
        return React.createElement(LocalLocationCard, Object.assign({}, EMPTY_LOCATION_DATA, options));
    }
    if (!location) {
        if (error && renderError) {
            return renderError(error);
        }
        return renderFallback ? renderFallback() : null;
    }
    const imageId = location.image ? location.image.profile : null;
    const geojson = location.geojson;
    const type = geojson === null || geojson === void 0 ? void 0 : geojson.type;
    const coordinates = type === 'Point' ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates : undefined;
    const line = type === 'LineString'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const areas = type === 'Polygon'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const locationData = {
        id: location._id,
        illustration: imageId
            ? getImageUrl(imageId, 'icon_128') || undefined
            : undefined,
        title: localize((_b = location.nameLocalized) === null || _b === void 0 ? void 0 : _b.full, location.name.full, locale),
        description: localize((_c = location.nameLocalized) === null || _c === void 0 ? void 0 : _c.description, location.name.description || '', locale),
        tags: localize((_d = location.nameLocalized) === null || _d === void 0 ? void 0 : _d.tag, location.name.tag, locale),
        coordinates,
        line,
        areas,
        address: {
            street: location.street || undefined,
            number: location.number || undefined,
            addition: location.addition || undefined,
            city: location.city || undefined,
        },
        url: location.url,
    };
    return React.createElement(LocalLocationCard, Object.assign({}, locationData, options));
}

import { createContext, useContext } from 'react';
export const DEFAULT_PAGE_DATA = {
    page: null,
    pageEvent: null,
    pageLocation: null,
    currentUser: null,
    currentGroups: null,
};
const PageDataContext = createContext(DEFAULT_PAGE_DATA);
export const PageDataProvider = PageDataContext.Provider;
export function usePageData() {
    return useContext(PageDataContext);
}

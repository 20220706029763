/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { fetchMedia, typeCheck } from './core';
import { RequiresAuthentication, RequiresDomain } from './errors';

interface BalanceOverviewResponseOk {
  balance: { cents: number };
  debt: { cents: number };
  payment: {
    _id: string;
    amount: { cents: number };
    name: { full: string };
    state?: {
      id?: 'paid' | 'completed' | 'created';
      put?: { at?: { unix: number } };
    };
  }[];
  transaction: {
    _id: string;
    amount: { cents: number };
    name: { full: string };
    groupRef: { groupShareMethod: string };
    productRef: {
      product: {
        name: { full: string };
        price: { cents: number };
        image: { profile?: string };
        _id: string;
      };
    }[];
    state?: {
      id?: 'paid' | 'completed' | 'created';
      put?: { at?: { unix: number } };
    };
    meta?: {
      lockers?: LockerItem[];
    };
  }[];
}

export type LockerItem = {
  locker_number: string;
  code: string;
  zone: null | string;
};

interface BalanceOverviewResponsError {
  message: string | null;
}

export type BalanceOverviewResponse =
  | BalanceOverviewResponseOk
  | BalanceOverviewResponsError;

interface BalanceCreditItem {
  _id: string;
  value: number;
  name: string;
  unix?: number;
}

interface BalanceDebitItem {
  _id: string;
  value: number;
  name: string;
  products: {
    name: string;
    price: number;
    image: string | null;
    _id: string;
  }[];
  unix?: number;
}

export interface BalanceOverViewStateValid {
  ok: true;
  value: number;
  withdrawals: BalanceDebitItem[];
  deposits: BalanceCreditItem[];
  lockers: LockerItem[];
}

export interface BalanceOverViewStateInvalid {
  ok: false;
  message: string;
}

export type BalanceOverViewState =
  | BalanceOverViewStateValid
  | BalanceOverViewStateInvalid;

function isBalanceOverviewOk(
  response: object | string
): response is { data: BalanceOverviewResponseOk } {
  return !!(
    typeof response === 'object' &&
    response &&
    'data' in response &&
    'balance' in (response as { data: object }).data
  );
}

const ACCEPT = 'application/json';

export function fetchBalance(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): ReturnType<typeof getBalance_> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  return getBalance_(endpoint, authorization, signal, debug).catch((err) => {
    return {
      ok: false,
      message: 'message' in err ? err.message : err + '',
    } as const;
  });
}

async function getBalance_(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<BalanceOverViewState> {
  const result = await fetchMedia(`${endpoint}/user/balance-overview`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });

  if (isBalanceOverviewOk(result)) {
    const response: Readonly<BalanceOverviewResponseOk> = typeCheck(result);

    return {
      ok: true,
      value: response.balance.cents,
      lockers: response.transaction
        .filter((transaction) => (transaction.meta?.lockers ?? [])?.length > 0)
        .map((transaction) => transaction.meta?.lockers ?? [])
        .reduce((result, lockers) => result.concat(lockers), []),
      withdrawals: response.transaction
        .filter(
          (transaction) =>
            !transaction.state ||
            !transaction.state.id ||
            transaction.state.id === 'completed' ||
            transaction.state.id === 'paid'
        )
        .map((transaction) => ({
          _id: transaction._id,
          value: transaction.amount.cents,
          name: transaction.name.full,
          products: (transaction.productRef || []).map(({ product }) => ({
            _id: product._id,
            name: product.name?.full || JSON.stringify(product),
            image:
              (product.image?.profile &&
                `${endpoint}api/image/${product.image.profile}/icon_64`) ||
              null,
            price: product.price?.cents || 0,
          })),
          unix: transaction.state?.put?.at?.unix || undefined,
        })),
      deposits: response.payment
        .filter(
          (transaction) =>
            transaction.state?.id &&
            (transaction.state.id === 'completed' ||
              transaction.state.id === 'paid')
        )
        .map((transaction) => ({
          _id: transaction._id,
          value: transaction.amount.cents,
          name: transaction.name.full,
          unix: transaction.state?.put?.at?.unix || undefined,
        })),
    } as const;
  }

  return {
    ok: false,
    message:
      (typeof result === 'string'
        ? result
        : (result as { message: string }).message) || 'Something went wrong',
  } as const;
}

import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Chip, MD2LightTheme as DefaultTheme } from 'react-native-paper';
import { blendColors } from 'use-alpha-blended-color';
import { cleanTag, normalizeTag, rgb } from './utils';
export function EventTag({ tag, designed, }) {
    const id = cleanTag(tag);
    const icon = eventTagIconFor(id);
    if (!id) {
        return null;
    }
    return (React.createElement(Chip, { mode: designed ? 'outlined' : 'flat', theme: DefaultTheme, icon: designed && icon
            ? ({ color, allowFontScaling, size }) => (React.createElement(MaterialCommunityIcons, { name: icon, size: 12, color: color, allowFontScaling: allowFontScaling }))
            : icon, style: [
            styles.tag,
            designed
                ? styles.designed
                : {
                    backgroundColor: colorFor(id),
                },
        ], textStyle: designed ? styles.textDesigned : styles.text }, normalizeTag(id)));
}
export function eventTagIconFor(tag) {
    switch (tag) {
        case 'active': {
            return 'run-fast';
        }
        case 'assigned-seating': {
            return 'seat';
        }
        case 'charity': {
            return 'charity';
        }
        case 'dark': {
            return 'weather-night';
        }
        case 'dutch': {
            return 'translate-off';
        }
        case 'engels': {
            return 'translate-off';
        }
        case 'english': {
            return 'translate-off';
        }
        case 'entertainment': {
            return 'balloon';
        }
        case 'explore': {
            return 'map-search';
        }
        case 'food': {
            return 'food-fork-drink';
        }
        case 'has-personal-version': {
            return 'account-alert';
        }
        case 'hybrid': {
            return 'account-switch';
        }
        case 'live-stream': {
            return 'video';
        }
        case 'nederlands': {
            return 'translate-off';
        }
        case 'online': {
            return 'account-network';
        }
        case 'outside': {
            return 'sunglasses';
        }
        case 'party': {
            return 'music';
        }
        case 'personal': {
            return 'account-alert';
        }
        case 'physical': {
            return 'account-group';
        }
        case 'presentation': {
            return 'presentation';
        }
        case 'quiet': {
            return 'ear-hearing-off';
        }
        case 'sensory-friendly': {
            return 'gauge-empty';
        }
        case 'webinar': {
            return 'laptop';
        }
        case 'wheelchair-friendly': {
            return 'wheelchair-accessibility';
        }
        case 'workshop': {
            return 'school';
        }
    }
}
const green = rgb(blendColors([255, 255, 255, 0.8], [127, 255, 127, 1]));
const blue = rgb(blendColors([255, 255, 255, 0.8], [127, 127, 255, 1]));
const red = rgb(blendColors([255, 255, 255, 0.8], [255, 127, 127, 1]));
const pink = rgb(blendColors([255, 255, 255, 0.8], [255, 127, 255, 1]));
const gray = rgb(blendColors([255, 255, 255, 0.8], [127, 127, 127, 1]));
const yellow = rgb(blendColors([255, 255, 255, 0.8], [255, 255, 127, 1]));
function colorFor(tag) {
    switch (tag) {
        case 'active': {
            return blue;
        }
        case 'assigned-seating': {
            return yellow;
        }
        case 'charity': {
            return gray;
        }
        case 'explore': {
            return gray;
        }
        case 'has-personal-version': {
            return gray;
        }
        case 'hybrid': {
            return yellow;
        }
        case 'live-stream': {
            return red;
        }
        case 'online': {
            return red;
        }
        case 'outside': {
            return green;
        }
        case 'party': {
            return pink;
        }
        case 'personal': {
            return gray;
        }
        case 'physical': {
            return blue;
        }
        case 'workshop': {
            return gray;
        }
    }
}
const styles = StyleSheet.create({
    text: {
        fontSize: 10,
        marginTop: 4,
        color: 'rgba(0, 0, 0, 0.87)',
    },
    textDesigned: {
        fontSize: 10,
        margin: 0,
        padding: 0,
        minHeight: 12,
        lineHeight: 12,
    },
    designed: {
        paddingVertical: 0,
        lineHeight: 12,
        minHeight: 12,
        backgroundColor: 'rgba(0, 0, 0, .05)',
        borderColor: 'rgba(0, 0, 0, .12)',
    },
    tag: {
        marginTop: 6,
        width: 'auto',
        marginRight: 6,
        justifyContent: 'center',
    },
});

import { __rest } from "tslib";
import React, { Fragment, useMemo } from 'react';
import { StyleSheet, View, } from 'react-native';
import { Card, Headline, IconButton, Surface, Text, Title, useTheme, } from 'react-native-paper';
import { useLocaleAction, useLocalization, useLocale, CURRENT_LOCALE, } from './useLocale';
import { defineTranslations, i18n, rgba } from './utils';
export { useLocale, CURRENT_LOCALE };
const DEBUG_FORMAT = false;
defineTranslations({
    en: {
        blocks: {
            actions: {
                translate: 'Translate content',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                translate: 'Vertaal tekst',
            },
        },
    },
});
/** @deprecated {useLocalization} */
export function useTranslation(original, translation) {
    const [useTranslation, translate] = useTranslationAction();
    if (!original) {
        return [translation || '', translate];
    }
    if (!translation) {
        return [original, translate];
    }
    return [
        useTranslation ? translation || original : original || translation,
        translate,
    ];
}
/** @deprecated {useLocale}, {useLocaleAction} */
export function useTranslationAction() {
    const [locale, translate] = useLocaleAction();
    return [locale !== i18n.defaultLocale, translate];
}
export function TextBlock(_a) {
    var { translation, localizedText, text, textLocalized = localizedText, options, formattedText = text, formattedTextLocalized = textLocalized } = _a, others = __rest(_a, ["translation", "localizedText", "text", "textLocalized", "options", "formattedText", "formattedTextLocalized"]);
    if (textLocalized) {
        return (React.createElement(LocalizedTextBlock, Object.assign({ text: formattedText, textLocalized: formattedTextLocalized, options: options }, others)));
    }
    if (translation || (options === null || options === void 0 ? void 0 : options.translatable)) {
        return (React.createElement(LegacyTranslationTextBlock, Object.assign({ text: formattedText, translation: translation, options: options }, others)));
    }
    return React.createElement(TextTextBlock, Object.assign({ text: formattedText, options: options }, others));
}
function LocalizedTextBlock({ options, text, textLocalized, style, round, textStyle, renderFallback, }) {
    const value = useLocalization(textLocalized, text);
    const { roundness } = useTheme();
    if (!value) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content },
                React.createElement(TextContent, { content: value, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })))));
}
function LegacyTranslationTextBlock({ options, text, translation, style, round, textStyle, renderFallback, }) {
    const [value, translate] = useTranslation(text, translation);
    const { roundness } = useTheme();
    if (!value) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content, accessibilityLiveRegion: !(options === null || options === void 0 ? void 0 : options.hideTranslatable) && translation ? 'polite' : undefined },
                React.createElement(TextContent, { content: value, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })),
            !!translation && !(options === null || options === void 0 ? void 0 : options.hideTranslatable) ? (React.createElement(IconButton, { accessibilityLabel: i18n.t('blocks.actions.translate'), icon: "translate", onPress: translate, style: styles.button, disabled: options && options.translatable === false })) : null)));
}
function TextTextBlock({ options, text, style, round, textStyle, renderFallback, }) {
    const { roundness } = useTheme();
    if (!text) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content },
                React.createElement(TextContent, { content: text, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })))));
}
function TextContent({ content, size, style, }) {
    switch (size || 'paragraph') {
        case 'paragraph': {
            return React.createElement(FormattedContent, { content: content, style: style });
        }
        case 'title': {
            return React.createElement(Title, { style: [styles.title, style] }, content);
        }
        case 'headline': {
            return React.createElement(Headline, { style: [styles.title, style] }, content);
        }
    }
}
function FormattedContent({ content, style, }) {
    return useParseFormattedContent(content);
}
function useParseFormattedContent(content, style) {
    return useMemo(() => {
        return (React.createElement(Fragment, null, content.split('\n').map((line, i) => {
            let match = line.match(REGEX_UL);
            if (match) {
                return (React.createElement(UnorderedListItem, { key: i, match: match, style: style }, line));
            }
            match = line.match(REGEX_OL);
            if (match) {
                return (React.createElement(OrderedListItem, { key: i, match: match, style: style }, line));
            }
            return (React.createElement(Text, { key: i, style: [style, { lineHeight: 22, marginBottom: 4 }] },
                React.createElement(InlineContent, null, line)));
        })));
    }, [content]);
}
export const REGEX_UL = /^\s*?([-*>]) (.*)$/;
export const SYMBOL_FOR_LIST = {
    '-': `\u2043`,
    '*': `\u2022`,
    '>': '\u2023',
};
function UnorderedListItem({ match, style, }) {
    var _a;
    const symbol = match[1];
    const content = match[2];
    return (React.createElement(View, { style: { flexDirection: 'row', marginBottom: 4, maxWidth: '100%' } },
        React.createElement(View, { style: { paddingHorizontal: 0, width: 24, marginRight: 4 } },
            React.createElement(Text, { numberOfLines: 1, style: [
                    style,
                    { fontVariant: ['tabular-nums'], lineHeight: 22, width: 24 },
                ] },
                ' ', (_a = SYMBOL_FOR_LIST[symbol]) !== null && _a !== void 0 ? _a : '\u2022')),
        React.createElement(View, { style: { flex: 1 } },
            React.createElement(Text, { style: [style, { lineHeight: 22 }] },
                React.createElement(InlineContent, null, content)))));
}
export const REGEX_OL = /^\s*?([0-9]+?)\. (.*)$/;
function OrderedListItem({ match, style, }) {
    const ordinal = match[1];
    const content = match[2];
    return (React.createElement(View, { style: { flexDirection: 'row', marginBottom: 4, maxWidth: '100%' } },
        React.createElement(View, { style: { paddingHorizontal: 0, width: 24, marginRight: 4 } },
            React.createElement(Text, { numberOfLines: 1, style: [
                    style,
                    { fontVariant: ['tabular-nums'], lineHeight: 22, width: 24 },
                ] },
                ordinal,
                ".")),
        React.createElement(View, { style: { flex: 1 } },
            React.createElement(Text, { style: [style, { lineHeight: 22 }] },
                React.createElement(InlineContent, null, content)))));
}
function InlineContent({ children: content, style, }) {
    const parts = useFormattedParts(content);
    return (React.createElement(Fragment, null, parts.map(({ style, part }, i) => (React.createElement(Text, { key: i, style: style }, part)))));
}
export function useFormattedParts(content) {
    return useMemo(() => formattedParts(content), [content]);
}
export function formattedParts(content) {
    // We absolutely want fresh copies in each component, because this will change
    // the last index property during matching
    const REGEX_STRIKE = /~~([^~]+?)~~/g;
    const REGEX_BOLD = /\*\*([^*]+?)\*\*/g;
    const REGEX_ITALIC = /(?:^|[^*])\*([^*]+?)\*/g;
    const REGEX_BOLD_ALT = /__([^_]+?)__/g;
    const REGEX_ITALIC_ALT = /(?:^|[^_])_([^_]+?)_/g;
    let matches = [];
    let match;
    while ((match = REGEX_STRIKE.exec(content))) {
        const start = match.index;
        const end = match.index + match[0].length;
        matches.push({
            start,
            end,
            type: 'strike',
            content: match[1],
            sliced: content.slice(start, end),
            shift: match[0].indexOf(match[1]),
            nested: [],
        });
    }
    while ((match = REGEX_BOLD.exec(content))) {
        const start = match.index;
        const end = match.index + match[0].length;
        matches.push({
            start,
            end,
            type: 'bold',
            content: match[1],
            sliced: content.slice(start, end),
            shift: match[0].indexOf(match[1]),
            nested: [],
        });
    }
    while ((match = REGEX_BOLD_ALT.exec(content))) {
        const start = match.index;
        const end = match.index + match[0].length;
        matches.push({
            start,
            end,
            type: 'bold',
            content: match[1],
            sliced: content.slice(start, end),
            shift: match[0].indexOf(match[1]),
            nested: [],
        });
    }
    while ((match = REGEX_ITALIC.exec(content))) {
        const shift = match['0'][0] !== '*' ? 1 : 0;
        const cut = match['0'][match.length - 1] !== '*' ? 1 : 0;
        const start = match.index + shift;
        const end = match.index + match[0].length - cut;
        matches.push({
            start,
            end,
            type: 'italic',
            content: match[1],
            sliced: content.slice(start, end),
            shift: match[0].indexOf(match[1]) - shift,
            nested: [],
        });
    }
    while ((match = REGEX_ITALIC_ALT.exec(content))) {
        const shift = match['0'][0] !== '_' ? 1 : 0;
        const cut = match['0'][match.length - 1] !== '_' ? 1 : 0;
        const start = match.index + shift;
        const end = match.index + match[0].length - cut;
        matches.push({
            start,
            end,
            type: 'italic',
            content: match[1],
            sliced: content.slice(start, end),
            shift: match[0].indexOf(match[1]) - shift,
            nested: [],
        });
    }
    matches.sort((a, b) => a.start - b.start);
    const marked = [];
    let index = 1;
    for (let pointer = 0; pointer < matches.length;) {
        while (index < matches.length) {
            if (DEBUG_FORMAT) {
                console.log('[comparing]', {
                    index: { i: index, m: matches[index] },
                    pointer: { i: pointer, m: matches[pointer] },
                });
            }
            if (matches[index].end < matches[pointer].end) {
                matches[pointer].nested.push(matches[index]);
                marked.push(matches[index]);
                index += 1;
            }
            else {
                break;
            }
        }
        pointer += 1;
        index = pointer + 1;
    }
    // Remove nested matches
    matches = matches.filter((m) => !marked.includes(m));
    if (DEBUG_FORMAT) {
        console.log(content, matches.slice());
    }
    const parts = [];
    if (matches.length === 0) {
        return [{ part: content, style: [] }];
    }
    // Pre match content
    if (matches[0]['start'] > 0) {
        parts.push({
            part: content.slice(0, matches[0]['start']),
            style: [],
        });
    }
    processMatch(content, content.length, matches.shift(), matches, parts);
    if (DEBUG_FORMAT) {
        console.log(parts);
    }
    return parts;
}
function processMatch(content, boundary, match, matches, results, extraStyles) {
    const index = match.start;
    const contentIndex = index + match.shift;
    const contentIndexEnd = index + match.shift + match.content.length;
    const activeStyles = [
        styleForType(match.type),
        extraStyles,
    ];
    const nestedMatch = match.nested[0];
    const nextMatch = matches[0];
    if (DEBUG_FORMAT) {
        console.log('[process match]', {
            match,
            range: [contentIndex, contentIndexEnd],
            rangeC: content.slice(contentIndex, contentIndexEnd),
            content: match.content,
            inner: nestedMatch,
            next: nextMatch,
            boundary,
        });
    }
    if (nestedMatch) {
        // If the next match is nested
        if (nestedMatch.start > contentIndex) {
            const preContent = content.slice(contentIndex, nestedMatch.start);
            if (DEBUG_FORMAT) {
                console.log('== pre ==', { match, c: preContent });
            }
            results.push({
                part: preContent,
                style: activeStyles,
            });
        }
        processMatch(content, contentIndexEnd, nestedMatch, match.nested.slice(1), results, activeStyles);
        if (nestedMatch.end < contentIndexEnd) {
            const postContent = content.slice(nestedMatch.end, contentIndexEnd);
            if (DEBUG_FORMAT) {
                console.log('== pos ==', { match, c: postContent });
            }
            results.push({
                part: postContent,
                style: activeStyles,
            });
        }
    }
    else {
        if (DEBUG_FORMAT) {
            console.log('-- con --', { match, c: match.content });
        }
        results.push({ part: match.content, style: activeStyles });
    }
    if (nextMatch) {
        // If there is content between this match and the next match
        if (nextMatch.start >= match.end) {
            const betweenContent = content.slice(match.end, nextMatch.start);
            if (DEBUG_FORMAT) {
                console.log('-- btw --', { match, c: betweenContent });
            }
            results.push({
                part: betweenContent,
                style: [],
            });
            processMatch(content, boundary, nextMatch, matches.slice(1), results);
        }
    }
    else {
        // If there is content after this match and the end
        if (content.length > match.end) {
            const afterContent = content.slice(match.end, boundary);
            if (DEBUG_FORMAT) {
                console.log('-- aft --', { match, c: afterContent });
            }
            results.push({
                part: afterContent,
                style: [],
            });
        }
    }
}
function styleForType(type) {
    switch (type) {
        case 'bold': {
            return { fontWeight: 'bold' };
        }
        case 'italic': {
            return { fontStyle: 'italic' };
        }
        case 'strike': {
            return {
                textDecorationStyle: 'solid',
                textDecorationLine: 'line-through',
            };
        }
    }
}
const styles = StyleSheet.create({
    title: { margin: 0 },
    wrapper: { elevation: 1, paddingBottom: 4, paddingTop: 12 },
    contentWrapper: { flexDirection: 'row', width: '100%' },
    content: { flex: 1 },
    paragraph: { marginBottom: 10, lineHeight: 22 },
    dragging: {
        elevation: 8,
    },
    editing: {
        borderWidth: 2,
        borderColor: rgba([255, 127, 127, 0.3]),
    },
    button: { marginHorizontal: 12, marginVertical: 0 },
});

import { __rest } from "tslib";
import { Action } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
export function ActionBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value } = block, _a = block.options, { spacing, round } = _a, options = __rest(_a, ["spacing", "round"]), { next, previous, dragging } = block;
    const shouldCollapseMarginTop = false && !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const style = spacing
        ? {
            marginTop: shouldCollapseMarginTop ? 0 : spacing * 8,
            marginBottom: spacing * 8,
        }
        : undefined;
    return createElement(Action, Object.assign(Object.assign(Object.assign({}, value), options), { round: round
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined, style, surface: true, renderFallback }));
}

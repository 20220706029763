import { __rest } from "tslib";
import { useDestination } from '@introcloud/blocks-interface';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Surface, useTheme } from 'react-native-paper';
import { AccentButton, PrimaryButton, TextButton, OutlinedButton, } from './Button';
import { useLocalization } from './useLocale';
export function Action(_a) {
    var { surface, round, inset, renderFallback } = _a, props = __rest(_a, ["surface", "round", "inset", "renderFallback"]);
    const { roundness } = useTheme();
    const finalLabel = useLocalization(props.labelLocalized, props.label);
    if (!finalLabel) {
        return renderFallback ? renderFallback() : null;
    }
    if (surface) {
        const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
        const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
        return (React.createElement(Surface, { style: [
                styles.surface,
                {
                    borderTopLeftRadius: topRadius,
                    borderTopRightRadius: topRadius,
                    borderBottomLeftRadius: bottomRadius,
                    borderBottomRightRadius: bottomRadius,
                },
            ] },
            React.createElement(Action, Object.assign({}, props, { inset: inset, surface: false }))));
    }
    if (inset) {
        return (React.createElement(View, { style: styles.inset },
            React.createElement(Action, Object.assign({}, props, { inset: false }))));
    }
    return React.createElement(ActionButton, Object.assign({}, props, { style: [styles.button, props.style] }));
}
function ActionButton(_a) {
    var { mode, destination, label, labelLocalized, contentStyle } = _a, other = __rest(_a, ["mode", "destination", "label", "labelLocalized", "contentStyle"]);
    const finalLabel = useLocalization(labelLocalized, label);
    const onPress = useDestination(destination);
    const finalStyle = [contentStyle];
    if (Platform.OS === 'web' && other.disabled) {
        finalStyle.push({ cursor: 'not-allowed' });
    }
    switch (mode) {
        case 'primary': {
            return (React.createElement(PrimaryButton, Object.assign({}, other, { contentStyle: finalStyle, onPress: onPress }), finalLabel));
        }
        case 'accent': {
            return (React.createElement(AccentButton, Object.assign({}, other, { contentStyle: finalStyle, onPress: onPress }), finalLabel));
        }
        case 'text': {
            return (React.createElement(TextButton, Object.assign({}, other, { contentStyle: finalStyle, onPress: onPress }), finalLabel));
        }
        case 'outlined': {
            return (React.createElement(OutlinedButton, Object.assign({}, other, { contentStyle: finalStyle, onPress: onPress }), finalLabel));
        }
        default: {
            return (React.createElement(PrimaryButton, Object.assign({}, other, { contentStyle: finalStyle, onPress: onPress }), finalLabel));
        }
    }
}
const styles = StyleSheet.create({
    surface: {
        elevation: 1,
        paddingHorizontal: 16,
    },
    inset: {
        marginHorizontal: 16,
    },
    button: { marginRight: 'auto', maxWidth: '100%' },
});

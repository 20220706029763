import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { useBlockData } from '../BlockData';
import { BlockQueryKeys } from '../BlockQueryKeys';
export function useBlockDataPage(pageId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getInfoById } = useBlockData();
    const _b = useQuery(BlockQueryKeys.page(pageId), ({ signal }) => getInfoById(pageId, { signal }), Object.assign({ enabled: enabled && !!pageId }, options)), { data: event, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: event, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}

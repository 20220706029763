import { useBlockImageUrl } from '@introcloud/blocks-interface';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Avatar, Chip } from 'react-native-paper';
import { ContactMenu } from './ContactMenu';
export function ContactChip({ contact, showChat = true }) {
    var _a;
    const [active, setActive] = useState(false);
    const toggleChipDetails = useCallback(() => setActive((prev) => !prev), [setActive]);
    const { data: illustration } = useBlockImageUrl(contact.image.profile, 'icon_64');
    const initials = [contact.name.first, contact.name.last]
        .filter(Boolean)
        .map((name) => name[0])
        .join('');
    const firstName = contact.name.first || contact.name.full.split(' ').shift();
    const anchor = (React.createElement(Chip, { avatar: illustration ? (React.createElement(Avatar.Image, { source: { uri: illustration, width: 65, height: 64 }, size: 24 })) : (React.createElement(Avatar.Text, { size: 24, label: initials })), onPress: toggleChipDetails, style: styles.chip }, firstName));
    return (React.createElement(ContactMenu, { active: active, onDismiss: toggleChipDetails, anchor: anchor, id: contact._id, name: contact.name.first || contact.name.full.split(' ')[0], phone: ((_a = contact.mobile) === null || _a === void 0 ? void 0 : _a.valid) && contact.mobile.value
            ? `${contact.mobile.countrycode || ''}${contact.mobile.value}`
            : undefined, showChat: showChat }));
}
const styles = StyleSheet.create({
    chip: {
        marginBottom: 4,
        marginRight: 2,
        marginLeft: 2,
    },
});

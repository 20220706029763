import { fetchMedia } from 'fetch-media';

import { ApiClientError, RequiresDomain } from './errors';

const ACCEPT = 'application/json';

export async function listUserPushTokens(
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<unknown> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  return await fetchMedia(`${endpoint}/application/user/push-token`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
}

export async function registerPushToken(
  endpoint: string,
  token: string,
  authorization: string,
  expoSlug: string,
  userId?: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<void> {
  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new ApiClientError('Authorization is required');
  }

  if (!expoSlug) {
    throw new ApiClientError('expoSlug is required');
  }

  const urlData = [
    encodeURIComponent(token),
    'expo-slug',
    encodeURIComponent(expoSlug),
    userId ? encodeURIComponent(userId) : null,
  ]
    .filter(Boolean)
    .join('/');

  await fetchMedia(`${endpoint}/public/push-token/${urlData}`, {
    headers: {
      accept: ACCEPT,
      authorization,
    },
    signal,
    debug,

    disableFormData: true,
    disableFormUrlEncoded: true,
  });
}

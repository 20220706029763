import { fetchMedia } from 'fetch-media';

import { typeCheck } from './core';
import {
  ApiClientError,
  RequiresAuthentication,
  RequiresDomain,
} from './errors';
import {
  TactileTimeslotList,
  TactileTimeslot,
  TactileTimeslotPreview,
} from './types';

const ACCEPT = 'application/json';

export async function fetchApplicationTimeslots(
  eventId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTimeslotList> {
  if (!eventId) {
    throw new ApiClientError('Missing event id to fetch timeslots for');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/timeslot/list/${eventId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

/**
 * Fetch the timeslot (no preview), once it's active. This will fail if the user
 * is not authorized to view the timeslot, or the timeslot is not yet "now".
 *
 * @param timeslotId timeslot ID
 * @param endpoint
 * @param authorization
 * @param signal
 * @param debug
 * @returns
 */
export async function fetchApplicationTimeslot(
  timeslotId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTimeslot> {
  if (!timeslotId) {
    throw new ApiClientError('Missing timeslot id to fetch');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/timeslot/${timeslotId}`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function registerApplicationTimeslot(
  timeslotId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTimeslotPreview> {
  if (!timeslotId) {
    throw new ApiClientError('Missing timeslot id to register for');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/timeslot/${timeslotId}/register`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function registerGroupApplicationTimeslot(
  timeslotId: string,
  groupId: string,
  endpoint: string,
  authorization: string,
  excludeUserIds: string[],
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTimeslotPreview> {
  if (!timeslotId) {
    throw new ApiClientError('Missing timeslot id to register for');
  }

  if (!groupId) {
    throw new ApiClientError('Missing group id to register for');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const body = {
    excludeUserIds,
  };

  const result = await fetchMedia(
    `${endpoint}/application/timeslot/${timeslotId}/group/${groupId}/register`,
    {
      headers: {
        accept: ACCEPT,
        contentType: 'application/json',
        authorization,
      },
      method: 'POST',
      body,
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export async function unregisterApplicationTimeslot(
  timeslotId: string,
  endpoint: string,
  authorization: string,
  signal?: AbortSignal,
  debug?: boolean
): Promise<TactileTimeslotPreview> {
  if (!timeslotId) {
    throw new ApiClientError('Missing timeslot id to unregister for');
  }

  if (!endpoint) {
    throw new RequiresDomain();
  }

  if (!authorization) {
    throw new RequiresAuthentication();
  }

  const result = await fetchMedia(
    `${endpoint}/application/timeslot/${timeslotId}/remove`,
    {
      headers: {
        accept: ACCEPT,
        authorization,
      },
      signal,
      debug,

      disableFormData: true,
      disableFormUrlEncoded: true,
    }
  );
  return typeCheck(result);
}

export function fetchRegisteredTimeslot(
  timeslots: readonly TactileTimeslotPreview[]
): TactileTimeslotPreview | undefined {
  return timeslots.find(
    (timeslot) =>
      timeslot.registeredEventGuest !== null &&
      Boolean(timeslot.registeredEventGuest.eventGuestId)
  );
}

import { __rest } from "tslib";
import { useBlockData, useBlockDataEvent, useBlockSettings, } from '@introcloud/blocks-interface';
import React from 'react';
import { EventListItemDescriptionFocus } from './EventListItemDescriptionFocus';
import { EventListItemDesigned } from './EventListItemDesigned';
import { localize, useLocale } from './useLocale';
export function RemoteEventListItem(_a) {
    var { source, renderFallback, renderError } = _a, other = __rest(_a, ["source", "renderFallback", "renderError"]);
    const { EventListItem: { type, lines }, } = useBlockSettings();
    const { getImageUrl } = useBlockData();
    const { data, loading, error } = useBlockDataEvent(source);
    const locale = useLocale();
    const finalData = loading || !data
        ? dataOrPlaceholder(Object.assign({ source }, other), lines)
        : extractData(data, other, { getImageUrl }, locale);
    if (finalData === null || !source || (!data && !loading)) {
        if (error && renderError) {
            return renderError(error);
        }
        // error?
        return renderFallback ? renderFallback() : null;
    }
    switch (type) {
        case 'description-focus': {
            return (React.createElement(EventListItemDescriptionFocus, Object.assign({}, finalData, other, { loading: loading })));
        }
        case 'designed': {
            // TODO: implement
            return (React.createElement(EventListItemDesigned, Object.assign({}, finalData, other, { loading: loading })));
        }
        default: {
            return (React.createElement(EventListItemDescriptionFocus, Object.assign({}, finalData, other, { loading: loading })));
        }
    }
}
function dataOrPlaceholder(_a, lines) {
    var { source } = _a, other = __rest(_a, ["source"]);
    if (typeof source !== 'string') {
        return Object.assign(Object.assign(Object.assign({}, source), other), { loading: false });
    }
    return Object.assign(Object.assign({ id: source, title: ' ', description: ' \n ' + (lines === 3 ? '\n ' : ''), duration: {
            start: 0,
            end: 0,
        }, tags: [], locationIds: [], fallbackLocation: undefined }, other), { hasTimeslots: false, loading: true });
}
function extractData(data, other, utils, locale) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (data === null) {
        return null;
    }
    const imageId = data.image ? data.image.profile || data.image.banner : null;
    const title = localize((_a = data.nameLocalized) === null || _a === void 0 ? void 0 : _a.full, data.name.full, locale);
    const description = localize((_b = data.nameLocalized) === null || _b === void 0 ? void 0 : _b.description, data.name.description, locale);
    const tags = localize((_c = data.nameLocalized) === null || _c === void 0 ? void 0 : _c.tag, data.name.tag, locale);
    return Object.assign(Object.assign({ id: data._id, title, description: description || '', duration: {
            start: ((_e = (_d = data.duration) === null || _d === void 0 ? void 0 : _d.start) === null || _e === void 0 ? void 0 : _e.unix) || 0,
            end: ((_g = (_f = data.duration) === null || _f === void 0 ? void 0 : _f.end) === null || _g === void 0 ? void 0 : _g.unix) || 0,
        }, tags: tags || [], locationIds: (data.locationRef || [])
            .map((ref) => ref.locationId)
            .filter(Boolean), fallbackLocation: undefined, illustration: imageId
            ? utils.getImageUrl(imageId, 'icon_128') || undefined
            : undefined, hasTimeslots: Boolean((_j = (_h = data.module) === null || _h === void 0 ? void 0 : _h.timeslot) === null || _j === void 0 ? void 0 : _j.active) }, other), { loading: false });
}

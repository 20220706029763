import { __rest } from "tslib";
import { useMutation, useQuery, useQueryClient, } from 'react-query';
import { BlockQueryKeys } from '../BlockQueryKeys';
import { useBlockData } from '../BlockData';
export function useBlockDataCheckinStatus(eventId, _a = {}, _b) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    var _c = _b === void 0 ? {} : _b, onSettled = _c.onSettled, mutationOptions = __rest(_c, ["onSettled"]);
    const { getCheckinStatus, setCheckinStatus } = useBlockData();
    const queryClient = useQueryClient();
    const queryKey = BlockQueryKeys.eventCheckinStatus(eventId);
    const _d = useQuery(queryKey, ({ signal }) => getCheckinStatus(eventId, { signal }), Object.assign({ enabled: enabled && !!eventId }, options)), { data: state, error } = _d, others = __rest(_d, ["data", "error"]);
    const { mutateAsync, isLoading: isMutating } = useMutation(queryKey, (next) => setCheckinStatus(eventId, next), Object.assign({ onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(queryKey);
            onSettled && onSettled(data, error, variables, context);
        } }, mutationOptions));
    return Object.assign({ data: state, loading: others.isLoading || state === undefined, refreshing: (others.isFetching && !others.isLoading) || isMutating, error, reload: others.refetch, change: mutateAsync }, others);
}

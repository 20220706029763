import { createElement } from 'react';
import { List, Surface, useTheme } from 'react-native-paper';
export function BalanceBlock(block) {
    const { roundness } = useTheme();
    return createElement(Surface, {
        style: { borderRadius: roundness, overflow: 'hidden', elevation: 1 },
        children: createElement(List.Item, {
            style: { borderRadius: roundness, overflow: 'hidden' },
            left: () => createElement(List.Icon, { icon: 'account-cash-outline' }),
            title: '42.13 EUR',
            description: 'Your (fake) balance',
            right: () => createElement(List.Icon, { icon: 'chevron-right' }),
        }),
    });
}

import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { BlockQueryKeys } from '../BlockQueryKeys';
import { useBlockData } from '../BlockData';
export function useBlockDataLocation(locationId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getLocationById } = useBlockData();
    const _b = useQuery(BlockQueryKeys.location(locationId), ({ signal }) => getLocationById(locationId, { signal }), Object.assign({ enabled: enabled && !!locationId, staleTime: 60 * 1000 }, options)), { data: location, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: location, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}

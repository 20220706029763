import { useBlockDataLocation, useBlockImageUrl, } from '@introcloud/blocks-interface';
import React from 'react';
import { LocalLocationListItem } from './LocalLocationListItem';
import { localize, useLocale } from './useLocale';
const EMPTY_LOCATION_DATA = {
    id: '',
    coordinates: undefined,
    title: '',
    description: '',
};
export function RemoteLocationListItem({ id, renderFallback, renderError, }) {
    var _a, _b;
    const { data: location, loading, error } = useBlockDataLocation(id);
    const locale = useLocale();
    const { data: image } = useBlockImageUrl((location === null || location === void 0 ? void 0 : location.image.profile) || null, 'icon_128');
    if (loading) {
        return React.createElement(LocalLocationListItem, Object.assign({}, EMPTY_LOCATION_DATA, { loading: true }));
    }
    if (!location) {
        if (error && renderError) {
            return renderError(error);
        }
        return renderFallback ? renderFallback() : null;
    }
    const title = localize((_a = location.nameLocalized) === null || _a === void 0 ? void 0 : _a.full, location.name.full, locale);
    const description = localize((_b = location.nameLocalized) === null || _b === void 0 ? void 0 : _b.description, location.name.description, locale);
    const geojson = location.geojson;
    const type = geojson === null || geojson === void 0 ? void 0 : geojson.type;
    const coordinates = type === 'Point' ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates : undefined;
    const line = type === 'LineString'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const areas = type === 'Polygon'
        ? geojson === null || geojson === void 0 ? void 0 : geojson.coordinates
        : undefined;
    const locationData = {
        id: location._id,
        illustration: image || undefined,
        title,
        description: description || '',
        coordinates,
        line,
        areas,
        address: {
            street: location.street || undefined,
            number: location.number || undefined,
            addition: location.addition || undefined,
            city: location.city || undefined,
        },
        url: location.url,
    };
    return React.createElement(LocalLocationListItem, Object.assign({}, locationData));
}

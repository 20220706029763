import { StoredMemoryValue, useMemoryValue, useMutableMemoryValue, } from 'expo-use-memory-value';
import { useCallback } from 'react';
import { i18n } from './utils';
export const CURRENT_LOCALE = new StoredMemoryValue('blocks.settings.locale', false, i18n.defaultLocale);
// TODO cycle languages instead of toggle
CURRENT_LOCALE.subscribe((value) => {
    var _a;
    if (value === null) {
        i18n.locale = i18n.defaultLocale;
    }
    else {
        i18n.locale =
            (_a = i18n.availableLocales.find((known) => known === value)) !== null && _a !== void 0 ? _a : i18n.availableLocales[0];
    }
    console.debug(`[locale] change to ${value} (actual: ${i18n.locale})`);
});
CURRENT_LOCALE.hydrate();
export function useLocale() {
    return useMemoryValue(CURRENT_LOCALE) || i18n.defaultLocale;
}
export function useLocaleAction() {
    const [locale, setLocale] = useMutableMemoryValue(CURRENT_LOCALE);
    const translate = useCallback((next = null) => {
        if (next) {
            setLocale(next);
            return;
        }
        setLocale((prev) => {
            if (!prev) {
                prev = i18n.defaultLocale;
            }
            const index = i18n.availableLocales.indexOf(prev);
            return i18n.availableLocales[index + 1] || i18n.availableLocales[0];
        });
    }, [setLocale]);
    return [locale || i18n.defaultLocale, translate];
}
export function useLocalization(textLocalized, fallback) {
    const locale = useLocale();
    return localize(textLocalized, fallback, locale);
}
export function localize(textLocalized, fallback, locale = i18n.defaultLocale) {
    if (!textLocalized) {
        return fallback;
    }
    return textLocalized[locale] || fallback;
}

export const BlockQueryKeys = {
    balance() {
        return ['blocks', 'balance'];
    },
    event(id) {
        return ['blocks', 'event', id || '-'];
    },
    eventCheckinStatus(eventId) {
        return ['blocks', 'event', eventId || '-', 'check-in-status'];
    },
    eventSubEvents(eventId) {
        return ['blocks', 'event', eventId || '-', 'sub-events'];
    },
    eventTimeslots(eventId, userId) {
        return ['blocks', 'event', eventId || '-', 'timeslots', userId || 'public'];
    },
    location(id) {
        return ['blocks', 'location', id || '-'];
    },
    locationEvents(locationId) {
        return ['blocks', 'location', locationId || '-', 'events'];
    },
    gameMap(id) {
        return ['blocks', 'game-map', id || '-'];
    },
    image(imageId, targetSize) {
        return ['blocks', 'image', imageId || '-', 'size', targetSize];
    },
    page(pageId) {
        return ['blocks', 'page', pageId || '-'];
    },
    sponsor(id) {
        return ['blocks', 'sponsor', id || '-'];
    },
    sponsorKind(kind, cacheKey) {
        return ['blocks', 'sponsor', kind, cacheKey];
    },
    timeslot(id, userId) {
        return ['blocks', 'timeslot', id || '-', userId || 'public'];
    },
    videoPreview(id) {
        return ['blocks', 'video', id || '-'];
    },
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import { cleanTag, normalizeTag } from './utils';
export function LocationTag({ tag }) {
    const id = cleanTag(tag);
    const icon = locationTagIconFor(id);
    if (!id || !icon) {
        return null;
    }
    return (React.createElement(Chip, { icon: locationTagIconFor(id), style: [
            styles.tag,
            {
                backgroundColor: undefined,
            },
        ], textStyle: styles.text }, normalizeTag(id)));
}
export function locationTagIconFor(tag) {
    switch (tag) {
        case 'association': {
            return 'account-group';
        }
        case 'bar': {
            return 'beer';
        }
        case 'bike-parking': {
            return 'parking';
        }
        case 'cafe': {
            return 'coffee';
        }
        case 'campus': {
            return 'school';
        }
        case 'charity': {
            return 'charity';
        }
        case 'club': {
            return 'music';
        }
        case 'coffee': {
            return 'coffee';
        }
        case 'culture': {
            return 'pillar';
        }
        case 'fast-food': {
            return 'food';
        }
        case 'gallery': {
            return 'image-frame';
        }
        case 'hotel': {
            return 'bed';
        }
        case 'landmark': {
            return 'terrain';
        }
        case 'museum': {
            return 'pillar';
        }
        case 'park': {
            return 'tree';
        }
        case 'parking': {
            return 'parking';
        }
        case 'religious': {
            return 'christianity';
        }
        case 'restaurant': {
            return 'silverware-fork-knife';
        }
        case 'route': {
            return 'map-marker-path';
        }
        case 'sport': {
            return 'football';
        }
        case 'university': {
            return 'school';
        }
    }
}
const styles = StyleSheet.create({
    text: {
        fontSize: 10,
        marginTop: 4,
        color: 'rgba(0, 0, 0, 0.87)',
    },
    tag: {
        marginTop: 6,
        width: 'auto',
        marginRight: 6,
        justifyContent: 'center',
    },
});

import { __rest } from "tslib";
import Color from 'color';
import React, { useMemo } from 'react';
import { Button, useTheme } from 'react-native-paper';
import { useAlphaBlendedColor } from 'use-alpha-blended-color';
import { AA_THRESHOLD_CONTRAST, useContrast, useHasContrastOnDark, useHasContrastOnLight, } from 'use-color-luminance';
export function PrimaryButton(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    const theme = useTheme();
    const blendedPrimary = useAlphaBlendedColor(theme.colors.primary, theme.colors.background);
    const isDark = useMemo(() => new Color(blendedPrimary).isDark(), [blendedPrimary]);
    return (React.createElement(Button, Object.assign({ mode: "contained", dark: isDark }, props), children));
}
export function AccentButton(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    const theme = useTheme();
    const blendedAccent = useAlphaBlendedColor(theme.colors.accent, theme.colors.background);
    const isDark = useMemo(() => new Color(blendedAccent).isDark(), [blendedAccent]);
    return (React.createElement(Button, Object.assign({ mode: "contained", dark: isDark, buttonColor: theme.colors.accent }, props), children));
}
export function SurfaceButton(_a) {
    var { children, labelStyle } = _a, props = __rest(_a, ["children", "labelStyle"]);
    const theme = useTheme();
    const blendedSurface = useAlphaBlendedColor(theme.colors.surface, theme.colors.background);
    const isDark = useMemo(() => new Color(blendedSurface).isDark(), [blendedSurface]);
    return (React.createElement(Button, Object.assign({ mode: "contained", dark: isDark, buttonColor: theme.colors.surface, textColor: theme.colors.onSurface, labelStyle: [{ color: theme.colors.onSurface }, labelStyle] }, props), children));
}
export function OutlinedButton(_a) {
    var _b;
    var { children, style } = _a, props = __rest(_a, ["children", "style"]);
    const theme = useTheme();
    const primaryHasContrast = useContrast(theme.colors.primary, theme.colors.surface) >=
        AA_THRESHOLD_CONTRAST;
    const accentHasContrast = useContrast(theme.colors.accent, theme.colors.surface) >=
        AA_THRESHOLD_CONTRAST;
    const colorToUse = primaryHasContrast
        ? theme.colors.primary
        : accentHasContrast
            ? theme.colors.accent
            : theme.colors.onSurface;
    const blended = useAlphaBlendedColor(colorToUse, theme.colors.surface);
    const finalStyle = [
        {
            borderColor: props.disabled
                ? theme.colors.disabled
                : (_b = props.color) !== null && _b !== void 0 ? _b : theme.colors.primary,
        },
        style,
    ];
    const colorIsDark = useMemo(() => new Color(blended).isDark(), [blended]);
    return (React.createElement(Button, Object.assign({ mode: "outlined", dark: colorIsDark, textColor: colorToUse }, props, { style: finalStyle }), children));
}
export function TextButton(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    const { colors: { primary, accent, text }, dark, } = useTheme();
    if (dark) {
        return (React.createElement(DarkThemeTextButton, Object.assign({}, props, { primary: primary, accent: accent, text: text }), children));
    }
    return (React.createElement(LightThemeTextButton, Object.assign({}, props, { primary: primary, accent: accent, text: text }), children));
}
function DarkThemeTextButton(_a) {
    var { children, primary, accent, text } = _a, props = __rest(_a, ["children", "primary", "accent", "text"]);
    const primaryWorks = useHasContrastOnDark(primary);
    const accentWorks = useHasContrastOnDark(accent);
    const textWorks = useHasContrastOnDark(text);
    const color = [
        primaryWorks && primary,
        accentWorks && accent,
        textWorks && text,
    ]
        .filter(Boolean)
        .shift();
    return (React.createElement(Button, Object.assign({ mode: "text", textColor: color || 'white' }, props), children));
}
function LightThemeTextButton(_a) {
    var { children, primary, accent, text } = _a, props = __rest(_a, ["children", "primary", "accent", "text"]);
    const primaryWorks = useHasContrastOnLight(primary);
    const accentWorks = useHasContrastOnLight(accent);
    const textWorks = useHasContrastOnLight(text);
    const color = [
        primaryWorks && primary,
        accentWorks && accent,
        textWorks && text,
    ]
        .filter(Boolean)
        .shift();
    return (React.createElement(Button, Object.assign({ mode: "text", textColor: color || 'black' }, props), children));
}

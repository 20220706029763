import { __rest } from "tslib";
import { useMutation, useQuery, useQueryClient, } from 'react-query';
import { BlockQueryKeys } from '../BlockQueryKeys';
import { useBlockData } from '../BlockData';
export function useBlockDataTimeslots(eventId, userId, _a = {}, _b) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    var _c = _b === void 0 ? {} : _b, onSettled = _c.onSettled, mutationOptions = __rest(_c, ["onSettled"]);
    const { getTimeslots, joinTimeslotRegistration, joinTimeslotRegistrationAsGroup, leaveTimeslotRegistration, } = useBlockData();
    const queryClient = useQueryClient();
    const queryKey = BlockQueryKeys.eventTimeslots(eventId, userId);
    const _d = useQuery(queryKey, ({ signal }) => getTimeslots(eventId, { signal }), Object.assign({ enabled: enabled && !!eventId }, options)), { data: timeslots, error } = _d, others = __rest(_d, ["data", "error"]);
    const { mutateAsync: change, error: changeError, isLoading: isChanging, } = useMutation(queryKey, joinTimeslotRegistration, Object.assign(Object.assign({}, mutationOptions), { onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(queryKey);
            onSettled && onSettled(data, error, variables, context);
        } }));
    const { mutateAsync: changeGroup, error: changeGroupError, isLoading: isChangingGroup, } = useMutation(queryKey, joinTimeslotRegistrationAsGroup, Object.assign(Object.assign({}, mutationOptions), { onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(queryKey);
            onSettled && onSettled(data, error, variables, context);
        } }));
    const { mutateAsync: leave, error: leaveError, isLoading: isLeaving, } = useMutation(queryKey, leaveTimeslotRegistration, Object.assign(Object.assign({}, mutationOptions), { onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(queryKey);
            onSettled && onSettled(data, error, variables, context);
        } }));
    return {
        data: timeslots,
        loading: others.isLoading,
        refreshing: (others.isFetching && !others.isLoading) ||
            isChanging ||
            isChangingGroup ||
            isLeaving,
        error: error || changeError || changeGroupError || leaveError,
        reload: others.refetch,
        change,
        changeGroup,
        leave,
    };
}
export function useBlockDataTimeslot(timeslotId, userId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getTimeslotsContent } = useBlockData();
    const _b = useQuery(BlockQueryKeys.timeslot(timeslotId, userId), ({ signal }) => getTimeslotsContent(timeslotId, { signal }), Object.assign({ enabled: enabled && !!timeslotId }, options)), { data: timeslot, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: timeslot, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}

import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Surface, useTheme } from 'react-native-paper';
import { EventListItem } from './EventListItem';
import { useLocalization } from './useLocale';
export function EventList({ title, titleLocalized, events, round, renderFallback, renderError, }) {
    const finalTitle = useLocalization(titleLocalized, title || '');
    const { roundness } = useTheme();
    if (events.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    // TODO suspense instead
    const items = events
        .map((event, index, self) => (React.createElement(EventListItem, { key: typeof event === 'string' ? event : event.id, source: event, isLast: index === self.length - 1, renderError: renderError, renderFallback: renderFallback })))
        .filter(Boolean);
    if (items.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Fragment, null,
        finalTitle ? (React.createElement(View, { style: styles.title },
            React.createElement(List.Subheader, null, finalTitle))) : null,
        React.createElement(Surface, { style: [styles.surface, borderStyle] },
            React.createElement(View, { style: [{ width: '100%', overflow: 'hidden' }, borderStyle] }, items))));
}
const styles = StyleSheet.create({
    title: { width: '100%' },
    surface: { elevation: 1 },
});

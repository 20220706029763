import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { useBlockData } from '../BlockData';
import { BlockQueryKeys } from '../BlockQueryKeys';
export function useBlockDataBalance(_a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getBalance } = useBlockData();
    const _b = useQuery(BlockQueryKeys.balance(), ({ signal }) => getBalance({ signal }), Object.assign({ enabled }, options)), { data: event, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: event, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}

import { __rest } from "tslib";
import { TextBlock as TextBlockBlock } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
export function TextBlock(_a) {
    var _b, _c, _d;
    var { hideTranslatable = true } = _a, block = __rest(_a, ["hideTranslatable"]);
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const value = Object.assign({}, block.value);
    if (((_b = block.options) === null || _b === void 0 ? void 0 : _b.forceTranslatable) && !value.translation) {
        value.translation = ' ';
    }
    if (!value.text &&
        (!value.textLocalized ||
            Object.values(value.textLocalized).filter(Boolean).length === 0)) {
        return renderFallback();
    }
    const size = ((_c = block.options) === null || _c === void 0 ? void 0 : _c.size) || 'paragraph';
    const style = size === 'paragraph'
        ? {}
        : {
            paddingBottom: block.dragging || !hasSurfacePadding(block.next) ? 16 : 0,
        };
    const previousConnectsAtBottom = extendsFullyToBottom(block.previous);
    const nextConnectsAtTop = extendsFullyToTop(block.next);
    // Paragraph
    return createElement(TextBlockBlock, Object.assign(Object.assign({}, value), { options: {
            size,
            hideTranslatable,
        }, style, round: ((_d = block.options) === null || _d === void 0 ? void 0 : _d.round)
            ? { top: !previousConnectsAtBottom, bottom: !nextConnectsAtTop }
            : undefined }));
}
